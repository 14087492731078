<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import _debounce from 'lodash.debounce';

import {
	videoMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
	page: {
		title: "Videos",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
		PageHeader,		
	},
	data() {
		return {
			title: this.$t('videos.title'),
			items: [],
			showModal: false,
			isLoading: false,
			fullPage: true,
			canCancel: false,
			useSlot: false,
			color: "#007bff",
			bgColor: "#ffffff",
			height: 128,
			width: 128,
			
			videos:[],
			submitted:false,
			confirmDelete:false,
			currentPage:1,
			perPage:8,
			rows:0,			
		};
	},
	mounted() {
		this.loadVideos();
	},
	created(){
		this.debounceGroups = _debounce(this.searchVideos.bind(this), 1000);
	},
	methods: {
		...videoMethods,
		
		getIconByVideoType(type){
			switch(type){
				case 'youtube':
					return 'bx bxl-youtube';
				case 'vimeo':
					return 'bx bxl-vimeo';
			}

			return 'bx bxs-videos';
		},
    loadVideos(){
			let loader = this.$loading.show();
			const params={
				q: `where[project]=${localStorage.getItem('current_project')}&populate[0]=campaign&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
			}

      this.getVideos(params).then((videos)=>{
				
				if(videos && videos.data){
					this.submitted= false;
					this.videos= videos.data;
					this.rows= videos.totalCount
			}
			}).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("videos.get_videos_error"),title:  this.$t("videos.title") });
			}).finally(()=>{
				this.isLoading = false;
				loader.hide();
			});
		},

		onDeleteVideoClicked(videoId){
				this.confirmDelete = true;
				this.videoId = videoId
		},

		onEditVideoClicked(videoId){
			this.$router.push(
				{
					path: `/new-video?id=${videoId}`,
				}
			).catch(() => {});
		},

		onConfirmRemoveVideoClicked(){
				let loader = this.$loading.show();
				this.deleteVideo(this.videoId).then(()=>{
						this.$notify({ type: 'success', text: this.$t("videos.delete_videos_error"),title:  this.$t("videos.title") });
						this.currentPage=1;
						this.loadVideos();
				}).catch(()=>{
					this.$notify({ type: 'error', text: this.$t("videos.delete_videos_error"),title:  this.$t("videos.title") });		
				}).finally(()=>{
						this.isLoading = false;
						loader.hide();
						this.videoId=null;
				})
		},

		onVideosListPageClicked() {
				this.loadVideos();
		},

		searchVideos(query){
			
			let loader = this.$loading.show();
      this.isLoading = true;

      if(query){
        this.videos=[];
        this.currentPage = 1;
				const params={
					q: `where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}
				this.getVideos(params).then((videos)=>{
          if(videos&& videos.data){
						this.videos= videos.data;
						this.rows= videos.totalCount;
          }
					this.isLoading = false;
					
        }).catch(()=>{
					this.$notify({ type: 'error', text: this.$t("videos.get_videos_error"),title:  this.$t("videos.title") });	
				}).finally(()=>{
					loader.hide();
				});
      }else{
				loader.hide();
				this.currentPage = 1;
        this.loadVideos();
      }
    },
    onNewVideoClicked(){
      this.$router.push({
          path: "/new-video",
        }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      });
    }
	}
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
			<div class="row">
					<div class="col-12">
						<div class="card">
						<div class="card-body">
								<div class="row ">
								<div class="col-sm-4">
										<div class="search-box me-2 mb-2 d-inline-block">
										<div class="position-relative">
												<input
												type="text"
												class="form-control"
												:placeholder="$t('common.search')"
												@input="debounceGroups($event.target.value)"
												/>
												<i class="bx bx-search-alt search-icon"></i>
										</div>
										</div>
								</div>
								<div class="col-sm-8">
										<div class="text-sm-end">
										<button
												type="button"
												class="btn btn-primary  mb-2 me-2"
												@click="onNewVideoClicked"
												v-if="videos.length>0 || query!=''">
												<i class="mdi mdi-plus me-1"></i> {{$t('videos.new_video')}}
										</button>
									
										</div>
								</div>
								<!-- end col-->
								</div>


						</div>
						<div class="table-responsive">
								<table  id="my-table" class="table align-middle table-nowrap table-hover mb-0">
										<thead class="table-light">
										<tr>
												<th scope="col" style="width: 70px;">#</th>
												<th scope="col">{{$t('videos.name')}}</th>
												<th scope="col">{{$t('videos.status')}}</th>
												<th scope="col">{{$t('common.actions')}}</th>
										</tr>
										</thead>
										<tbody>
										<tr v-for="video in videos" :key="video._id">
												<td>
													<i :class="getIconByVideoType(video.type)" style="font-size: 20px"/>
												</td>
												<td v-on:click="onEditVideoClicked(video._id)">
                          <a href="#"><h5 class="font-size-14 mb-1">
                              {{video.name}}
                          </h5></a>
                        </td>
												<td>
                          {{video.status == 'published' ? $t('videos.status_published') : $t('videos.status_draft')}}
												</td>
												<td>
												<b-dropdown
														class="card-drop"
														variant="white"
														right
														toggle-class="p-0"
														menu-class="dropdown-menu-end"
												>
														<template v-slot:button-content>
														<i class="mdi mdi-dots-horizontal font-size-18"></i>
														</template>

														<b-dropdown-item @click="onEditVideoClicked(video._id)">
														<i class="fas fa-edit text-success me-1"></i>
														{{$t('common.edit')}}
														</b-dropdown-item>

														<b-dropdown-item  @click="onDeleteVideoClicked(video._id)">
														<i class="fas fa-trash-alt text-danger me-1"></i>
														{{$t('common.delete')}}
														</b-dropdown-item>
												</b-dropdown>
											</td>
										</tr>
										</tbody>
								</table>
								<b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveVideoClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
										<p>{{$t('videos.remove_video_confirm')}}</p>
								</b-modal>
								</div>
						</div>
						<div class="row mb-4" v-if="rows > perPage">
							<div class="col">
									<div class="dataTables_paginate paging_simple_numbers float-end">
											<ul class="pagination pagination-rounded mb-0">
													<b-pagination
															@input="onVideosListPageClicked"
															v-model="currentPage"
															:total-rows="rows"
															:per-page="perPage"
													></b-pagination>
											</ul>
									</div>
							</div>
						</div>
					</div>
				</div>
	</Layout>
</template>
